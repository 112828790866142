import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
// import MuiBreadcrumbs from "@material-ui/core/Breadcrumbs"
// import EventNoteIcon from "@material-ui/icons/EventNote"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import Seo from "../components/seo"
import Title from "../components/title"
import Hero from "../components/hero"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
  item: {},
  link: {
    textDecoration: 'none !important'
  },
	name: {
		color: theme.palette.text.secondary,
		display: "flex",
		textAlign: "center",
		marginTop: theme.spacing(2.5),
    fontWeight: 400,
		justifyContent: "center",
	},
	imageContainer: {
		display: "block",
		overflow: "hidden",
	},
	image: {
		backgroundColor: theme.palette.common.white,
	}
}))

const AllAccessories = ({ data }) => {
  const classes = useStyles()

  const {
    datoCmsHeroImage = {},
    datoCmsCategoryAccessory = {}
  } = data || {}

  const { title, image: heroImage } = datoCmsHeroImage
  const categoryAccessory = (datoCmsCategoryAccessory && datoCmsCategoryAccessory.categoryType) || {}

  return (
    <Layout>
      <Hero title={title} image={heroImage} />
      <Container maxWidth="lg">
        <Box mt={10} display="flex" justifyContent="center">
          <Title>{title}</Title>
        </Box>
        <Grid container spacing={8} className={classes.grid}>
					{categoryAccessory.map((item, index) => (
						<Grid item md={4} sm={6} xs={12} key={index} className={classes.item}>
							<Link to={`/akcesoria/${item.slug}`} className={classes.link}>
								{item && item.image && (
									<div className={classes.imageContainer}>
										<GatsbyImage image={getImage(item.image)} alt={item.title} className={classes.image} />
									</div>
								)}
								<Typography variant="h5" className={classes.name}>{item.title}</Typography>
							</Link>
						</Grid>
					))}
				</Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query queryAllAccessories {
    datoCmsHeroImage(pageType: { eq: "akcesoria" }) {
      title
      image {
        gatsbyImageData
      }
    }
    datoCmsCategoryAccessory {
      categoryType {
        title
        category
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 1
            width: 500
            height: 500
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  }
`

export default AllAccessories